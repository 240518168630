import React from 'react';

import Carousel from '../../../Components/Carousel/Carousel';
import Image from '../../../Components/Image/Image';
import Icon from '../../../Components/Icon/Icon';

import SLIDE1_IMG_JPG from '../../../Assets/Reviews/dp3.jpg';
import SLIDE1_IMG_WEBP from '../../../Assets/Reviews/dp3.webp';
import SLIDE2_IMG_JPEG from '../../../Assets/Reviews/dp1.jpeg';
import SLIDE2_IMG_WEBP from '../../../Assets/Reviews/dp1.webp';
import SLIDE3_IMG_JPG from '../../../Assets/Reviews/dp2.jpg';
import SLIDE3_IMG_WEBP from '../../../Assets/Reviews/dp2.webp';
import {
  REVIEWS_TEXT_1,
  REVIEWS_TEXT_2,
  REVIEWS_TEXT_3,
  REVIEWS_TEXT_4,
  REVIEWS_TEXT_5,
  REVIEWS_TEXT_6,
  REVIEWS_TEXT_7,
  REVIEWS_TEXT_8,
  REVIEWS_TEXT_9,
  REVIEWS_TEXT_10,
  REVIEWS_TEXT_11
} from '../../../Constants';

import styles from './reviews.module.css';

const REVIEWS = [
  {
    id: 1,
    pic: SLIDE1_IMG_JPG,
    pic_webp: SLIDE1_IMG_WEBP,
    comments: REVIEWS_TEXT_1,
    name: REVIEWS_TEXT_2,
    designation: REVIEWS_TEXT_3
  },
  {
    id: 2,
    pic: SLIDE2_IMG_JPEG,
    pic_webp: SLIDE2_IMG_WEBP,
    comments: REVIEWS_TEXT_4,
    name: REVIEWS_TEXT_5,
    designation: REVIEWS_TEXT_6
  },
  {
    id: 3,
    pic: SLIDE3_IMG_JPG,
    pic_webp: SLIDE3_IMG_WEBP,
    comments: REVIEWS_TEXT_7,
    name: REVIEWS_TEXT_8,
    designation: REVIEWS_TEXT_9
  }
];

const Reviews = () => {
  return (
    <div data-testid="reviews" id="reviews" className={styles.container}>
      <div className={`${styles.content} center`}>
        <div className={styles.content_heading}>
          <p className="heading_text white_heading">{REVIEWS_TEXT_10}</p>
          <p className="body_text">{REVIEWS_TEXT_11}</p>
        </div>

        {/* Review Carousel */}
        <div className={styles.slider}>
          <Carousel controls={true} indicators={false} interval={5000}>
            {REVIEWS.map((review, index) => (
              <div
                className={`${styles.slide_content} center`}
                key={`${review}+${index}`}>
                <div className={styles.image_box}>
                  <Image
                    className={styles.image}
                    src={review.pic}
                    webpImage={review.pic_webp}
                    alt="ProfilePic"
                  />
                </div>
                <div className={styles.text_box}>
                  <p className="large_body_text">{review.comments}</p>
                  <div className={styles.star}>
                    <Icon icon="star" size="lg" />

                    <Icon icon="star" size="lg" />

                    <Icon icon="star" size="lg" />

                    <Icon icon="star" size="lg" />

                    <Icon icon="star" size="lg" />
                  </div>
                  <p className="large_body_text">- {review.name}</p>
                  <p className="large_body_text">{review.designation}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
